@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.table-card {
  align-items: center;
  border-bottom: 1px solid $ui-line-table;

  &.grid-3 {
    grid-template-columns: repeat(3, minmax(150px, 1fr));
  }

  &.grid-4 {
    grid-template-columns: 80px repeat(3, minmax(150px, 1fr));
  }

  &.grid-5 {
    grid-template-columns: 80px repeat(4, minmax(150px, 1fr));
  }

  &.grid-6 {
    grid-template-columns: 80px repeat(5, minmax(150px, 1fr));
  }

  &.grid-7 {
    grid-template-columns: 40px 100px 80px repeat(4, minmax(150px, 1fr));
  }

  &.grid-8 {
    grid-template-columns: 80px repeat(7, minmax(150px, 1fr));
  }

  &.grid-9 {
    grid-template-columns: 60px 60px repeat(7, minmax(150px, 1fr));
  }

  &.grid-10 {
    grid-template-columns: 80px repeat(9, minmax(120px, 1fr));
  }

  &.grid-12 {
    grid-template-columns: 80px repeat(11, minmax(120px, 1fr));
  }

  &.grid-14 {
    grid-template-columns: 60px 60px repeat(
        12,
        minmax(120px, 1fr)
      );
  }

  &.grid-15 {
    grid-template-columns:
      50px 60px repeat(2, 108px) 150px repeat(3, 120px) 90px
      repeat(2, minmax(120px, 1fr)) repeat(2, minmax(90px, 1fr)) repeat(2, minmax(120px, 1fr));
  }

  &.grid-16 {
    grid-template-columns:
      50px 60px repeat(2, 108px) 150px repeat(3, 120px) 90px
      repeat(2, minmax(120px, 1fr)) repeat(2, minmax(90px, 1fr)) repeat(2, minmax(120px, 1fr)) repeat(2, minmax(120px, 1fr));
  }

  &:last-of-type {
    border-bottom-width: 0;
  }

  &:not(.title) {
    * {
      -webkit-touch-callout: text;
      -webkit-user-select: text;
      -khtml-user-select: text;
      -moz-user-select: text;
      -ms-user-select: text;
      user-select: text;
    }
  }

  &.title {
    cursor: pointer;

    .table-card-col {
      .table-card-title {
        display: flex;
      }
    }
  }

  .table-card-col {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 16px 8px;

    .table-card-col-inner {
      position: relative;
      flex-direction: row;
      align-items: center;
    }

    .table-card-button {
      align-self: flex-start;
      padding: 6px 8px;
      border-radius: 6px;
      background-color: $ui-bg-dark;

      span {
        color: $ui-text-light;
      }

      .table-card-button-icon {
        width: 16px;
        height: 16px;
        fill: white;
      }
    }

    span {
      color: $ui-text-dark;
      font-size: 14px;
      font-weight: 400;

      &.link {
        cursor: pointer;
        text-decoration: underline;
        transition: all 0.35s ease-out 0s;

        &:hover {
          color: $ui-text-soft-blue;
          transition: all 0.35s ease-out 0s;
        }
      }
    }

    .table-card-title {
      display: none;
      margin-bottom: 3px;
      color: $ui-text-dark;
      font-size: 14px;
      font-weight: 800;
    }

    .table-card-sort {
      flex-grow: 0;
      flex-shrink: 0;
      width: 8px;
      height: 8px;
      margin-left: 8px;
      margin-bottom: 6px;
    }

    .table-card-info {
      width: 12px;
      height: 12px;
      margin-left: 8px;
      margin-bottom: 4px;
      fill: $ui-icon-menu;
    }

    &.blue {
      & > span {
        color: $ui-text-blue;
      }
    }


    &.red {
      & > span {
        color: $ui-text-red;
      }
    }
  }
}

.blueBg {
  background-color: rgba(110, 165, 227, 0.4) !important;
}

@media all and (max-width: $ipad) {
  .table-card {
    align-items: flex-start;
    padding: 12px 20px;
    border-bottom-width: 0;

    &.grid-6 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }

    &.grid-7 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
    }

    &.grid-8 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
    }

    &.grid-9 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(3, 1fr);
    }

    &.grid-10 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(4, 1fr);
    }

    &.grid-15 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(5, 1fr);
    }

    &.grid-16 {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(5, 1fr);
    }

    &.title {
      display: none;
      
      .table-card-col {
        display: none;
      }
    }

    .table-card-col {
      padding: 6px 20px;

      .table-card-title {
        display: flex;
      }
    }

    &:nth-child(2n) {
      background-color: $ui-bg-light;
    }
  }
}

@media all and (max-width: $mobile) {
  .table-card {
    padding: 8px 0;

    &.grid-6 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
    }

    &.grid-7 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, 1fr);
    }

    &.grid-8 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, 1fr);
    }

    &.grid-9 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(5, 1fr);
    }

    &.grid-10 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(5, 1fr);
    }

    &.grid-15 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(8, 1fr);
    }

    
    &.grid-16 {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(8, 1fr);
    }

  }
}

@media all and (max-width: 380px) {
  .table-card {
    padding: 6px 0;

    &.grid-15 {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(15, 1fr);
    }

    &.grid-16 {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(15, 1fr);
    }

    .table-card-col {
      padding: 0 16px;
    }
  }
}
