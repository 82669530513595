@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.home-page {
  flex: 1 1;
  padding: 20px 0;

  .home-info {
    grid-template-columns: 1fr 200px;
    grid-gap: 16px;
    width: 100%;
  }
}

/* home-title */
.home-about-wrap {
  width: 100%;
  background-color: $ui-bg-white;

  .home-button {
    width: 100%;
    max-width: 320px;
    margin: 16px 0;
  }

  header {
    width: 100%;
    background: #ffa231;
    background: -moz-linear-gradient(
      top,
      #ffa231 0%,
      #ffa231 15%,
      #e35500 85%,
      #e35500 100%
    );
    background: -webkit-linear-gradient(
      top,
      #ffa231 0%,
      #ffa231 15%,
      #e35500 85%,
      #e35500 100%
    );
    background: linear-gradient(
      to bottom,
      #ffa231 0%,
      #ffa231 15%,
      #e35500 85%,
      #e35500 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffa231', endColorstr='#e35500',GradientType=0 );

    .home-about-header-wrap {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 12px 0;

      .home-about-header-logo {
        flex-grow: 0;
        flex-shrink: 0;
        width: 52px;
        height: 52px;
        margin-right: 16px;
      }

      .home-about-header-title {
        flex-grow: 1;
        flex-shrink: 1;

        h2 {
          padding: 0;
          font-size: 32px;
        }

        p {
          color: $ui-text-white;
        }
      }

      .home-about-header-button {
        flex-grow: 0;
        flex-shrink: 0;
        width: 200px;
      }
    }
  }

  .home-about {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 40px;
    padding: 80px 0;

    .home-about-images {
      position: relative;
      width: 100%;
      padding: 0 0 80px 0;

      .home-about-image1 {
        width: 60%;
      }

      .home-about-image2 {
        position: absolute;
        right: 0;
        bottom: 40px;
        width: 50%;
        transform: scale(1);
        transition: all 0.35s ease-out 0s;

        &:hover {
          transform: scale(1.02);
          transition: all 0.35s ease-out 0s;
        }
      }
    }

    .home-about-info {
      width: 100%;
      align-items: flex-start;
      justify-content: center;

      & > h3 {
        margin-bottom: 12px;
        font-weight: 500;
        text-transform: uppercase;
      }

      & > p {
        margin-bottom: 12px;
      }

      & > button {
        width: 200px;
      }
    }
  }

  .home-steps {
    align-items: center;
    width: 100%;
    padding: 80px 0;
    background-color: $ui-bg-light;

    .home-steps-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 40px;
      padding: 0 40px;

      .home-steps-item {
        h3 {
          margin-bottom: 20px;
          color: $ui-text-orange;
          font-size: 24px;
          font-weight: 700;
          line-height: 1.22;
          //opacity: 0.6;
        }

        p {
          margin-bottom: 12px;
          color: $ui-text-dark;
          font-size: 13px;

          span {
            color: $ui-text-orange;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }
  }

  .home-mobile {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    align-items: center;
    width: 100%;
    padding: 80px 0;

    .home-mobile-image-wrap {
      width: 100%;

      .home-mobile-image {
        width: 100%;
      }
    }

    .home-mobile-info {
      & > h3 {
        margin-bottom: 12px;
        font-weight: 500;
        text-transform: uppercase;
      }

      & > p {
        margin-bottom: 12px;
      }
    }
  }

  footer {
    width: 100%;
    padding: 16px 0;
    background-color: $ui-bg-light;
  }
}

@media all and (max-width: $ipad) {
  .home-page {
    padding: 20px 0;

    .home-info {
      grid-template-columns: 1fr;
    }
  }
  .home-about-wrap {
    .home-steps {
      align-items: center;

      .home-steps-list {
        grid-template-columns: 1fr;
      }
    }

    .home-about {
      grid-template-columns: 1fr;

      .home-about-images {
        display: none;
      }

      .home-about-info {
        align-items: center;
      }
    }
  }
}

/* touchscreen device */
@media (pointer: coarse) {
}
