@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.table {
  width: 100%;
}

.table-adaptive {
  width: 100%;
}

.table-adaptive-wrap {
  width: 100%;
  margin: 16px 0;
  padding: 16px 20px;
  border-radius: 8px;
  background-color: $ui-bg-white;
  box-shadow: 0 0 19px $ui-shadow-table;
  overflow: hidden;
  overflow-x: auto;
}

@media all and (max-width: $ipad) {
  .table-adaptive-wrap {
    padding: 0;
  }
}
