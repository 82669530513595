@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../styles/media.scss";

.menu-left {
  position: relative;
  z-index: 2;
  width: 100%;
  background-color: $ui-bg-white;

  .menu-left-list {
    justify-content: flex-start;
    width: 100%;

    .menu-left-item {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-grow: 0;
      flex-shrink: 1;
      height: 60px;
      background-color: $ui-bg-white;

      .menu-left-item-wrap {
        position: relative;
        z-index: 2;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
        background-color: $ui-bg-white;
      }

      &:hover {
        span {
          left: 60px;
          transition: all 0.45s ease-out 0s;
        }
      }

      span {
        position: absolute;
        left: -200px;
        z-index: 1;
        padding: 8px;
        border-radius: 2px;
        color: $ui-text-white;
        font-size: 14px;
        line-height: 24px;
        background-color: $ui-bg-dark;
        white-space: nowrap;
      }

      .menu-left-item-icon {
        width: 24px;
        height: 24px;
        fill: $ui-icon-menu;
      }

      &.active {
        background-color: $ui-bg-light;

        .menu-left-item-wrap {
          background-color: $ui-bg-light;
        }

        span {
          color: $ui-text-orange;
          font-weight: 600;
        }

        .menu-left-item-icon {
          fill: $ui-icon-orange;
        }
      }
    }
  }
}

@media all and (max-width: $ipad) {
  .menu-left {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 10;
    width: 100%;
    height: 90px;
    background-color: $ui-bg-dark;

    .menu-left-list {
      flex-direction: row;
      height: 100%;
      padding: 0 20px;

      .menu-left-item {
        display: flex;
        flex-grow: 1;
        flex-shrink: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: auto;
        height: 100%;
        padding: 0 8px;

        span {
          color: $ui-text-light;
          text-align: center;
        }

        .menu-left-item-icon {
          width: 43px;
          height: 43px;
          margin-right: 0;
          margin-bottom: 13px;
          fill: $ui-icon-light;
        }

        &.active {
          background-color: transparent;

          span {
            color: $ui-text-amber;
          }

          .menu-left-item-icon {
            fill: $ui-icon-amber;
          }
        }
      }
    }
  }
}

@media all and (max-width: $mobile) {
  .menu-left {
    height: 84px;

    .menu-left-list {
      flex-wrap: wrap;
      padding: 0;
      padding: 0;

      .menu-left-item {
        flex: auto;
        height: 50%;
        margin-bottom: 0;

        span {
          font-size: 10px;

          span {
            display: none;
          }
        }

        .menu-left-item-icon {
          width: 28px;
          height: 28px;
          margin-bottom: 6px;
        }
      }
    }
  }
}
