@import "./colors.scss";
@import "./fonts.scss";
@import "./media.scss";

* {
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html {
  width: 100%;
  font-size: 14px;
  overflow: hidden;
}

body {
  width: 100%;
  margin: 0;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.22;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

div,
header,
footer,
nav,
section {
  display: flex;
  flex-direction: column;
}

/* UI BASE ELEMENTS */

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-family: "Montserrat";
}

a {
  cursor: pointer;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;

  &:active {
    border-style: outset;
    border-width: 0;
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &:-webkit-any-link:active {
    color: transparent;
  }
}

button {
  display: flex;
  cursor: pointer;
  padding: 0;
  border-width: 0;
  background-color: transparent;
  font-family: "Montserrat";
  -webkit-tap-highlight-color: transparent;

  &:active {
    border-style: outset;
    outline: none;
  }

  &:focus {
    outline: none;
  }

  &:-webkit-any-link:active {
    color: transparent;
  }
}

input,
textarea {
  padding: 0;
  border: {
    color: transparent;
    width: 0;
  }
  background-color: transparent;
  font-family: "Montserrat";
  -webkit-user-select: text; /* Chrome, Opera, Safari */
  -moz-user-select: text; /* Firefox 2+ */
  -ms-user-select: text; /* IE 10+ */
  user-select: text; /* Standard syntax */

  &:focus {
    outline: none;
  }
}

/* MAIN CONTAINERS */

#root {
  width: 100%;
  overflow: hidden;
}

#page {
  width: 100%;
  background-color: $ui-bg-light;
}

.page-content {
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
}

.ui-grid {
  display: grid;
}
