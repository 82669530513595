@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.section-title {
  align-items: center;
  justify-content: center;

  .section-title-logo {
    flex-direction: row;
    align-items: flex-end;
  }

  .section-title-icon2 {
    width: 40px;
    height: 40px;
    margin-bottom: 4px;
    fill: $ui-icon-orange;
    //transform: rotate(-26deg);
  }

  .section-title-icon {
    width: 60px;
    height: 50px;
    margin-bottom: 4px;
    fill: $ui-bg-dark;
  }

  h3 {
    margin: 12px 0;
    color: $ui-text-orange;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.2;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 32px;
    font-weight: 600;
  }

  &.left {
    align-items: flex-start;
  }
}

@media all and (max-width: $ipad) {
  .section-title {
    &.left {
      align-items: center;
    }
  }
}